import store from '@/store'

function checkPermission(el, binding) {
  const { value } = binding
  const permissions = store.getters && store.getters.permissions
  if (!value) {
    return
  }
  if (value instanceof Array) {
    if (value.length > 0) {
      const needPermissions = value

      let hasPermission = false
      needPermissions.forEach(need => {
        hasPermission &= permissions[need]
      })

      if (!hasPermission) {
        if (el.parentNode) {
          el.parentNode.removeChild(el)
        }
      }
    }
  } else if (!permissions[value]) {
    if (el.parentNode) {
      el.parentNode.removeChild(el)
    }
  }
  // throw new Error(`need roles! Like v-permission="['admin','editor']"`)
}

export default {
  inserted(el, binding) {
    checkPermission(el, binding)
  },
  update(el, binding) {
    checkPermission(el, binding)
  },
}
