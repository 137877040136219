// 时间简称
function timeState() {
  let now = new Date()
  let hours = now.getHours()
  if (hours >= 0 && hours <= 4) {
    return '凌晨好'
  }
  if (hours > 4 && hours <= 10) {
    return '上午好'
  }
  if (hours > 10 && hours <= 14) {
    return '中午好'
  }
  if (hours > 14 && hours <= 18) {
    return '下午好'
  }
  if (hours > 18 && hours <= 24) {
    return '晚上好'
  }
  return ''
}

function getLastWeek() {
  let date = new Date()
  console.log('aaaa', date)
  let week = [date.cxFormat('yyyy-MM-dd')]
  for (let i = 0; i < 6; i++) {
    date.setDate(date.getDate() - 1)
    week.push(date.cxFormat('yyyy-MM-dd'))
  }
  return week.reverse()
}

export default {
  Date: {
    timeState,
    getLastWeek,
  },
}
