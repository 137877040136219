import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress' // progress bar
// Routes'
import { isUserLoggedIn } from '@/auth/utils'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach(async (to, _, next) => {
  NProgress.start()
  const isLoggedIn = isUserLoggedIn()
  if (!isLoggedIn) {
    if (to.path === '/login') {
      next()
    } else {
      next({ name: 'auth-login' })
    }
    NProgress.done()
    return
  }
  const { asyncRoutes } = store.getters

  if (!asyncRoutes || asyncRoutes.length === 0) {
    try {
      const routes = await store.dispatch('user/generateRoutes')
      routes.push(
        {
          path: '*',
          redirect: 'error-404',
        },
      )
      router.addRoutes(routes)
      // hack method to ensure that addRoutes is complete
      // set the replace: true, so the navigation will not leave a history record
      next({ ...to, replace: true })
      return
    } catch (error) {
      console.log(error)
      router.addRoutes([
        {
          path: '*',
          redirect: 'error-404',
        },
      ])
      await store.dispatch('user/logout')
      next(`/login?redirect=${to.path}`)
      NProgress.done()
      return
    }
  }

  if (to.meta && to.meta.isLink) {
    window.open(to.path, '_blank')
    next({ path: '/' })
    NProgress.done()
    return
  }
  // // 连接websocket服务器
  // if (isLoggedIn) {
  //   Vue.prototype.$x.ws.connect()
  // }
  next()
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
