import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vue.prototype.$xtoast = {
  info: (content, title, position) => {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        // title: title | `${Vue.prototype.$t('toast.success')}`,
        title: title || '提示',
        icon: 'FeatherIcon',
        text: content,
        variant: 'primary',
      },
    }, {
      position: position || 'top-right',
    })
  },
  success: (content, title, position) => {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        // title: title | `${Vue.prototype.$t('toast.success')}`,
        title: title || '成功',
        icon: 'CheckIcon',
        text: content,
        variant: 'success',
      },
    }, {
      position: position || 'top-right',
    })
  },
  warning: (content, title, position) => {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: title || '警告',
        icon: 'BellIcon',
        text: content,
        variant: 'warning',
      },
    }, {
      position: position || 'top-right',
    })
  },
  error: (content, title, position) => {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: title || '失败',
        icon: 'XIcon',
        text: content,
        variant: 'danger',
      },
    }, {
      position: position || 'top-right',
    })
  },
}
