import Vue from 'vue'
import VueRouter from 'vue-router'

import auth from './routes/auth'
import dashboard from './routes/dashboard'
import userCenter from './routes/user-center'
// import workbench from './routes/workbench'
// import example from './routes/example'
// import system from './routes/system'
// import permission from './routes/permission'
// import resource from './routes/resource'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...auth,
    ...dashboard,
    ...userCenter,
    // ...workbench,
    // ...example,
    // ...system,
    // ...permission,
    // ...resource,
    // {
    //   path: '*',
    //   redirect: 'error-404',
    // },
  ],
})

export default router
