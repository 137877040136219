import Vue from 'vue'

import Utils from './utils/index'
import Biz from './biz/index'

// 按钮波纹颜色
Vue.prototype.$x = {
  ripple: {
    primary: 'rgba(113, 102, 240, 0.15)',
    secondary: 'rgba(186, 191, 199, 0.15)',
    success: 'rgba(40, 199, 111, 0.15)',
    danger: 'rgba(234, 84, 85, 0.15)',
    warning: 'rgba(255, 159, 67, 0.15)',
    info: 'rgba(0, 207, 232, 0.15)',
    dark: 'rgba(30, 30, 30, 0.15)',
    white: 'rgba(255, 255, 255, 0.15)',
    black: 'rgba(0, 0, 0, 0.15)',
  },
  color: {
    primary: '#7367f0',
    success: '#50cd89',
    info: '#00cfe8',
    warning: '#ff9f43',
    danger: '#f1416c',
  },
  utils: {
    ...Utils,
  },
  biz: {
    ...Biz,
  },
  // 获取url文件连接中的文件名
  getFilename(url) {
    return url ? url.substr(url.lastIndexOf('/') + 1) : ''
  },
  onCopy() {
    Vue.prototype.$xtoast.success('拷贝成功')
  },
  onError() {
    Vue.prototype.$xtoast.error('拷贝失败')
  },
}
