// 下划线转换驼峰
function toHump(name) {
  return name.replace(/_(\w)/g, (all, letter) => letter.toUpperCase())
}
// 驼峰转换下划线
function toLineLowerCase(name) {
  return name.replace(/([A-Z])/g, '_').toLowerCase()
}
// 驼峰转换下划线
function toLineUpperCase(name) {
  return name.replace(/([A-Z])/g, '_$1').toUpperCase()
}

export default {
  toHump,
  toLineLowerCase,
  toLineUpperCase,
}
