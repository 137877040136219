import router from '@/router'
import { setToken, setRefreshToken, logout } from '@/@core/auth/token'
import { getRoutes } from '@/api/system/menu'
import { getButtons } from '@/api/system/user'
import dashboard from '@/navigation/dashboard'

function isURL(s) {
  if (s.includes('html')) return true
  return /^http[s]?:\/\/.*/.test(s)
}

function setMenusDeep(newMenus, parent, routes) {
  routes.forEach(route => {
    if (route.meta.isHide === 2) {
      return
    }
    let newMenu = {
      title: route.meta.pageTitle,
      icon: route.meta.icon,
      href: route.href ? route.path : undefined,
    }
    if (route.children && route.children.length) {
      let childrens = []
      setMenusDeep(childrens, parent.concat([route.code]), route.children)
      newMenu.children = childrens
    } else if (!route.href) {
      newMenu.route = route.name
    }
    newMenus.push(newMenu)
  })
}

function setRoutesDeep(newRoutes, parent, routes) {
  routes.forEach(route => {
    if (route.alias !== 'menu') {
      return
    }
    if (route.children && route.children.length) {
      let childrens = []
      let childParent = parent.concat([route.code])
      setRoutesDeep(childrens, childParent, route.children)
      newRoutes.push({
        path: route.path,
        name: childParent.join('-'),
        // component: () => import('@/views/example/cropper.vue'),
        redirect: { name: childrens[0].name },
        meta: {
          pageTitle: route.name,
          icon: route.source,
          isHide: route.isOpen,
        },
        children: childrens,
      })
    } else {
      // let viewPath = `@/views${route.path}/index.vue`
      let newRoute = isURL(route.path) ? { href: isURL(route.path) } : {
        // component: () => require(`@/views${route.path}/index.vue`),
        component(resolve) {
          require([`@/views${route.path}/index.vue`], resolve)
        },
      }
      newRoute = Object.assign(newRoute, {
        path: route.path,
        name: parent.concat([route.code]).join('-'),
        meta: {
          pageTitle: route.name,
          icon: route.source,
          isHide: route.isOpen,
        },

      })
      newRoutes.push(newRoute)
    }
  })
}

function getAllChilds(childRoutes, routes) {
  routes.forEach(route => {
    if (route.children && route.children.length) {
      getAllChilds(childRoutes, route.children)
    } else {
      childRoutes.push(route)
    }
  })
}

const user = {
  namespaced: true,
  state: {
    routes: [],
    info: {},
    permissions: {},
    menus: [
      ...dashboard,
    ],
  },
  actions: {
    // 登出 并跳转登录
    toLogout({ commit }) {
      return new Promise(() => {
        commit('LOGOUT')
        router.replace({ name: 'auth-login' })
      })
    },

    // 登出
    logout({ commit }) {
      return new Promise(() => {
        commit('LOGOUT')
      })
    },
    // 设置用户信息
    setUserInfo({ commit }, info) {
      return new Promise(resolve => {
        commit('SET_USER_INFO', info)
        resolve()
      })
    },
    // 获取用户菜单
    generateRoutes({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        getRoutes().then(resp => {
          dispatch('getButtons')
          commit('SET_ROUTES', resp.data.data)
          commit('SET_MENUS')
          let newRoutes = []
          getAllChilds(newRoutes, state.routes)
          resolve(newRoutes)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取按钮权限
    getButtons({ commit }) {
      return new Promise(resolve => {
        getButtons().then(res => {
          const { data } = res.data
          commit('SET_PERMISSIONS', data)
          resolve()
        })
      })
    },
  },
  mutations: {
    SET_USER_INFO(state, data) {
      localStorage.setItem('userData', JSON.stringify(data))
      setToken(data.access_token)
      setRefreshToken(data.refresh_token)
      state.info = data.info
    },
    SET_ROUTES(state, data) {
      let newRoutes = []
      setRoutesDeep(newRoutes, [], data)
      state.routes = newRoutes
    },
    SET_MENUS(state) {
      if (state.routes && state.routes.length > 0) {
        let newMenus = []
        setMenusDeep(newMenus, [], state.routes)
        state.menus = [
          ...dashboard,
          ...newMenus,
        ]
      } else {
        state.menus = [
          ...dashboard,
        ]
      }
    },
    // 设置按钮权限
    SET_PERMISSIONS(state, permission) {
      let result = []

      function getCode(list) {
        list.forEach(ele => {
          if (typeof (ele) === 'object') {
            const chiildren = ele.children
            const { code } = ele
            if (chiildren) {
              getCode(chiildren)
            } else {
              result.push(code)
            }
          }
        })
      }

      getCode(permission)
      state.permissions = {}
      result.forEach(ele => {
        state.permissions[ele] = true
      })
    },
    LOGOUT(state) {
      logout()
      localStorage.removeItem('userData')
      state.info = {}
      state.routes = []
    },
  },

}
export default user
