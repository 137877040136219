import Vue from 'vue'

const EVENT = 'EVENT'
const REPORT_PROPERTY = 'REPORT_PROPERTY'
const READ_PROPERTY_REPLY = 'READ_PROPERTY_REPLY'
const READ_PROPERTY = 'READ_PROPERTY'
const WRITE_PROPERTY = 'WRITE_PROPERTY'
const WRITE_PROPERTY_REPLY = 'WRITE_PROPERTY_REPLY'
const CHILD = 'CHILD'
const CHILD_REPLY = 'CHILD_REPLY'
const FUNCTION_INVOKE = 'INVOKE_FUNCTION'
const FUNCTION_INVOKE_REPLY = 'INVOKE_FUNCTION_REPLY'
const METADATA = 'METADATA'
const STATE_CHECK = 'STATE_CHECK'
const STATE_CHECK_REPLY = 'STATE_CHECK_REPLY'

const REGISTER = 'REGISTER'
const UNREGISTER = 'UNREGISTER'
const OFFLINE = 'OFFLINE'
const ONLINE = 'ONLINE'
const DISCONNECT = 'DISCONNECT'
const DISCONNECT_REPLY = 'DISCONNECT_REPLY'

const READ_FIRMWARE = 'READ_FIRMWARE'
const READ_FIRMWARE_REPLY = 'READ_FIRMWARE_REPLY'
const REPORT_FIRMWARE = 'REPORT_FIRMWARE'
const PULL_FIRMWARE = 'PULL_FIRMWARE'
const PULL_FIRMWARE_REPLY = 'PULL_FIRMWARE_REPLY'
const UPGRADE_FIRMWARE = 'UPGRADE_FIRMWARE'
const UPGRADE_FIRMWARE_REPLY = 'UPGRADE_FIRMWARE_REPLY'
const UPGRADE_FIRMWARE_PROGRESS = 'UPGRADE_FIRMWARE_PROGRESS'
const LOG = 'LOG'
const TAG = 'TAG'
const ACKNOWLEDGE = 'ACKNOWLEDGE'
const OTHER = 'OTHER'

// 获取日志类型tag样式
function getLogTypeTag(type) {
  switch (Vue.prototype.$x.utils.toLineUpperCase(type.value)) {
    case READ_PROPERTY:
    case WRITE_PROPERTY:
    case 'FUNCTION_INVOKE':
    case STATE_CHECK:
    case READ_FIRMWARE:
    case PULL_FIRMWARE:
    case UPGRADE_FIRMWARE:
      return 'light-warning'
    case EVENT:
    case REPORT_PROPERTY:
    case READ_PROPERTY_REPLY:
    case WRITE_PROPERTY_REPLY:
    case CHILD:
    case CHILD_REPLY:
    case 'FUNCTION_REPLAY':
    case METADATA:
    case STATE_CHECK_REPLY:
    case DISCONNECT_REPLY:
    case READ_FIRMWARE_REPLY:
    case REPORT_FIRMWARE:
    case PULL_FIRMWARE_REPLY:
    case UPGRADE_FIRMWARE_REPLY:
    case UPGRADE_FIRMWARE_PROGRESS:
    case LOG:
    case TAG:
    case ACKNOWLEDGE:
    case OTHER:
    default:
      return 'light-primary'

    case REGISTER:
    case ONLINE:
      return 'light-success'

    case UNREGISTER:
    case OFFLINE:
    case DISCONNECT:
      return 'light-danger'
  }
}

// 获取日志类型描述
function getLogType(type) {
  switch (type) {
    case EVENT:
      return '事件上报'
    case REPORT_PROPERTY:
      return '属性上报'
    case READ_PROPERTY:
      return '读取属性'
    case READ_PROPERTY_REPLY:
      return '读取属性回复'
    case WRITE_PROPERTY:
      return '修改属性'
    case WRITE_PROPERTY_REPLY:
      return '修改属性回复'
    case CHILD:
      return '子设备消息'
    case CHILD_REPLY:
      return '子设备消息回复'
    case FUNCTION_INVOKE:
      return '调用功能'
    case FUNCTION_INVOKE_REPLY:
      return '调用功能回复'
    case METADATA:
      return '上报物模型'
    case STATE_CHECK:
      return '状态检查'
    case STATE_CHECK_REPLY:
      return '状态检查回复'

    case REGISTER:
      return '设备注册'
    case UNREGISTER:
      return '设备注销'
    case OFFLINE:
      return '离线'
    case ONLINE:
      return '上线'
    case DISCONNECT:
      return '断开连接'
    case DISCONNECT_REPLY:
      return '断开连接回复'

    case READ_FIRMWARE:
      return '读取固件信息'
    case READ_FIRMWARE_REPLY:
      return '读取固件信息回复'
    case REPORT_FIRMWARE:
      return '上报固件信息'
    case PULL_FIRMWARE:
      return '拉取固件信息'
    case PULL_FIRMWARE_REPLY:
      return '拉取固件信息回复'
    case UPGRADE_FIRMWARE:
      return '推送固件信息'
    case UPGRADE_FIRMWARE_REPLY:
      return '推送固件信息回复'
    case UPGRADE_FIRMWARE_PROGRESS:
      return '固件更新进度'
    case LOG:
      return '日志'
    case TAG:
      return '标签更新'
    case ACKNOWLEDGE:
      return '应答'

    case OTHER:
    default:
      return '其他'
  }
}

export default {
  getLogTypeTag,
  getLogType,
  LogType: {
    READ_PROPERTY,
    READ_PROPERTY_REPLY,
    WRITE_PROPERTY,
    WRITE_PROPERTY_REPLY,
    FUNCTION_INVOKE,
    FUNCTION_INVOKE_REPLY,
    STATE_CHECK,
    READ_FIRMWARE,
    PULL_FIRMWARE,
    UPGRADE_FIRMWARE,
    EVENT,
    REPORT_PROPERTY,
    CHILD,
    CHILD_REPLY,
    METADATA,
    STATE_CHECK_REPLY,
    DISCONNECT_REPLY,
    READ_FIRMWARE_REPLY,
    REPORT_FIRMWARE,
    PULL_FIRMWARE_REPLY,
    UPGRADE_FIRMWARE_REPLY,
    UPGRADE_FIRMWARE_PROGRESS,
    LOG,
    TAG,
    ACKNOWLEDGE,
    OTHER,
  },
}
