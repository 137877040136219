// 触发方式
const MANUAL = 'manual'
const TIMER = 'timer'
const DEVICE = 'device'
const SCENE = 'scene'

const ONLINE = 'online'
const OFFLINE = 'offline'
const PROPERTIES = 'properties'
const EVENT = 'event'
const FUNCTION = 'function'

export default {
  RuleEngine: {
    Operators: [
      { value: 'eq', label: '等于(=)' },
      { value: 'not', label: '不等于(!=)' },
      { value: 'gt', label: '大于(&gt;)' },
      { value: 'lt', label: '小于(&lt;)' },
      { value: 'gte', label: '大于等于(&gt;=)' },
      { value: 'lte', label: '小于等于(&lt;=)' },
      { value: 'like', label: '模糊(%)' },
    ],
    Trigger: {
      // 触发类型
      Mode: {
        MANUAL,
        TIMER,
        DEVICE,
        SCENE,
        DictData: [
          // { label: '手动触发', value: MANUAL },
          { label: '定时触发', value: TIMER },
          { label: '设备触发', value: DEVICE },
          { label: '场景触发', value: SCENE },
        ],
        AlarmDictData: [
          // { label: '手动触发', value: MANUAL },
          { label: '定时触发', value: TIMER },
          { label: '设备触发', value: DEVICE },
        ],
      },
      // 触发方式
      Type: {
        ONLINE,
        OFFLINE,
        PROPERTIES,
        EVENT,
        FUNCTION,
        DictData: [
          { value: ONLINE, label: '上线' },
          { value: OFFLINE, label: '离线' },
          { value: PROPERTIES, label: '属性' },
          { value: EVENT, label: '事件' },
          { value: FUNCTION, label: '功能' },
        ],
        AlarmDictData: [
          { value: ONLINE, label: '上线' },
          { value: OFFLINE, label: '离线' },
          { value: PROPERTIES, label: '属性' },
          { value: EVENT, label: '事件' },
        ],
      },
    },
  },
}
