const VIEW = 'view'
const ADD = 'add'
const EDIT = 'edit'
const DELETE = 'delete'
const RESET = 'reset'
const SEARCH = 'search'

export default {
  FormType: {
    VIEW,
    ADD,
    EDIT,
    DELETE,
    RESET,
    SEARCH,
  },
}
