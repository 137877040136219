import Vue from 'vue'
import website from '@/config/website'
// import baseUrl from '@/config/env'
//
// axios
import axios from 'axios'

axios.defaults.baseURL = website.prefix
// 默认超时时间
axios.defaults.timeout = 10000

const axiosIns = axios.create({
  // baseURL: baseUrl,
  // timeout: 10000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
