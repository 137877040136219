/**
 * 占位符替换
 * @returns {String}
 */
String.prototype.cxSignMix = function () {
  if (arguments.length === 0) return this
  const param = arguments[0]
  let str = this
  if (typeof (param) === 'object') {
    for (const key in param) {
      if (param[key]) {
        str = str.replace(new RegExp('\\{' + key + '\\}', 'g'), param[key])
      }
    }
  } else {
    for (let i = 0; i < arguments.length; i++) {
      str = str.replace(new RegExp('\\{' + i + '\\}', 'g'), arguments[i])
    }
  }
  return str
}

/**
 * 时间戳时间格式化
 * @param fmt
 * @returns {*}
 */
Number.prototype.cxFormat = function (fmt) {
  if (!fmt) {
    fmt = 'yyyy-MM-dd HH:mm:ss'
  }
  let ret
  let date = new Date(this)
  const opt = {
    'y+': date.getFullYear().toString(),
    'M+': (date.getMonth() + 1).toString(),
    'd+': date.getDate().toString(),
    'h+': date.getHours().toString(),
    'm+': date.getMinutes().toString(),
    's+': date.getSeconds().toString(),
  }
  Object.keys(opt).forEach(k => {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    }
  })
  return fmt
}

/**
 * 时间格式化
 * @param fmt
 * @returns {*}
 */
Date.prototype.cxFormat = function (fmt) {
  let ret
  let date = this
  const opt = {
    'y+': date.getFullYear().toString(),
    'M+': (date.getMonth() + 1).toString(),
    'd+': date.getDate().toString(),
    'h+': date.getHours().toString(),
    'm+': date.getMinutes().toString(),
    's+': date.getSeconds().toString(),
  }
  if (!fmt) {
    fmt = 'yyyy-MM-dd HH:mm:ss'
  }
  Object.keys(opt).forEach(k => {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    }
  })
  return fmt
}
