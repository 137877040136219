import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-upms/menu/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const getLazyList = (parentId, params) => request.axiosIns({
  url: '/apollo-upms/menu/lazy-list',
  method: 'get',
  params: {
    ...params,
    parentId,
  },
})

export const getLazyMenuList = (parentId, params) => request.axiosIns({
  url: '/apollo-upms/menu/lazy-menu-list',
  method: 'get',
  params: {
    ...params,
    parentId,
  },
})

export const getMenuList = (current, size, params) => request.axiosIns({
  url: '/apollo-upms/menu/menu-list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const getMenuTree = tenantId => request.axiosIns({
  url: '/apollo-upms/menu/tree',
  method: 'get',
  params: {
    tenantId,
  },
})

export const remove = ids => request.axiosIns({
  url: '/apollo-upms/menu/remove',
  method: 'post',
  params: {
    ids,
  },
})

export const add = row => request.axiosIns({
  url: '/apollo-upms/menu/submit',
  method: 'post',
  data: row,
})

export const update = row => request.axiosIns({
  url: '/apollo-upms/menu/submit',
  method: 'post',
  data: row,
})

export const getMenu = id => request.axiosIns({
  url: '/apollo-upms/menu/detail',
  method: 'get',
  params: {
    id,
  },
})

export const getTopMenu = () => request.axiosIns({
  url: '/apollo-upms/menu/top-menu',
  method: 'get',
})

export const getRoutes = topMenuId => request.axiosIns({
  url: '/apollo-upms/menu/routes',
  method: 'get',
  params: {
    topMenuId,
  },
})
