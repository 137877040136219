const ONLINE = 'online'
const OFFLINE = 'offline'
const NOT_ACTIVE = 'notActive'

export default {
  DevState: {
    ONLINE,
    OFFLINE,
    NOT_ACTIVE,
    DictData: [
      { text: '未启用', value: NOT_ACTIVE },
      { text: '在线', value: ONLINE },
      { text: '离线', value: OFFLINE },
    ],
  },
}
