import jwtDefaultConfig from './jwt/jwtDefaultConfig'

export function getToken() {
  return localStorage.getItem(jwtDefaultConfig.storageTokenKeyName)
}

export function getRefreshToken() {
  return localStorage.getItem(jwtDefaultConfig.storageRefreshTokenKeyName)
}

export function setToken(value) {
  localStorage.setItem(jwtDefaultConfig.storageTokenKeyName, value)
}

export function setRefreshToken(value) {
  localStorage.setItem(jwtDefaultConfig.storageRefreshTokenKeyName, value)
}

export function logout() {
  localStorage.removeItem(jwtDefaultConfig.storageTokenKeyName)
  localStorage.removeItem(jwtDefaultConfig.storageRefreshTokenKeyName)
}
