/**
 * 全局配置文件
 */
export default {
  key: '', // 配置主键,目前用于localStorage存储
  indexTitle: 'Saber Admin',
  clientId: 'saber', // 客户端id
  clientSecret: 'saber_secret', // 客户端密钥
  tenantMode: true, // 是否开启租户模式
  tenantId: '000000', // 管理组租户编号
  captchaMode: true, // 是否开启验证码模式
  switchMode: false, // 是否开启部门切换模式
  tokenTime: 3000,
  tokenHeader: 'Apollo-Auth',
  // http的status默认放行列表
  statusWhiteList: [],
  // 表格操作栏折叠数量,超过此数量操作变为更多
  tableActionFoldCount: 4,
  // 接口prefix
  prefix: process.env.NODE_ENV === 'development' ? '/api' : '/api',
}
