export default [
  {
    title: '仪表盘',
    countTag: true,
    tagVariant: 'light-warning',
    route: 'dashboard-home',
    icon: 'TrendingUpIcon',
    resource: 'dashboard',
  },
]
