const NONE = 'none'
const ES_ROW = 'default-row'
const ES_COLUMN = 'default-column'
const TDENGINE_COLUMN = 'tdengine-column'
const INFLUXDB_ROW = 'influx-row'
const INFLUXDB_COLUMN = 'influx-column'

export default {
  StorageStrategy: {
    DictData: [
      { name: '不存储', id: NONE, description: '不存储设备相关数据' },
      { name: 'ES-行式存储', id: ES_ROW, description: '每个设备的每一个属性为一行数据.适合设备每次上报部分属性' },
      { name: 'ES-列式存储', id: ES_COLUMN, description: '每个设备的全部属性为一行数据.需要设备每次上报全部属性' },
      { name: 'TDengine-列式存储', id: TDENGINE_COLUMN, description: '每个设备的全部属性为一行数据.需要设备每次上报全部属性' },
      { name: 'InfluxDB-行式存储', id: INFLUXDB_ROW, description: '每个设备的每一个属性为一行数据.适合设备每次上报部分属性' },
      { name: 'InfluxDB-列式存储', id: INFLUXDB_COLUMN, description: '每个设备的全部属性为一行数据.需要设备每次上报全部属性' },
    ],
  },
}
