import request from '@/auth/jwt/useJwt'

export function getList(current, size, params) {
  return request.axiosIns({
    url: '/apollo-ucenter/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}

export function remove(ids) {
  return request.axiosIns({
    url: '/apollo-ucenter/remove',
    method: 'post',
    params: {
      ids,
    },
  })
}

export function add(row) {
  return request.axiosIns({
    url: '/apollo-ucenter/submit',
    method: 'post',
    data: row,
  })
}

export function update(row) {
  return request.axiosIns({
    url: '/apollo-ucenter/update',
    method: 'post',
    data: row,
  })
}

export function updatePlatform(userId, userType, userExt) {
  return request.axiosIns({
    url: '/apollo-ucenter/update-platform',
    method: 'post',
    params: {
      userId,
      userType,
      userExt,
    },
  })
}

export function getUser(id) {
  return request.axiosIns({
    url: '/apollo-ucenter/detail',
    method: 'get',
    params: {
      id,
    },
  })
}

export function getUserPlatform(id) {
  return request.axiosIns({
    url: '/apollo-ucenter/platform-detail',
    method: 'get',
    params: {
      id,
    },
  })
}

export function getUserInfo() {
  return request.axiosIns({
    url: '/apollo-ucenter/info',
    method: 'get',
  })
}

export function resetPassword(userIds) {
  return request.axiosIns({
    url: '/apollo-ucenter/reset-password',
    method: 'post',
    params: {
      userIds,
    },
  })
}

export function updatePassword(oldPassword, newPassword, newPassword1) {
  return request.axiosIns({
    url: '/apollo-ucenter/update-password',
    method: 'post',
    params: {
      oldPassword,
      newPassword,
      newPassword1,
    },
  })
}

export function updateInfo(row) {
  return request.axiosIns({
    url: '/apollo-ucenter/update-info',
    method: 'post',
    data: row,
  })
}

export function grant(userIds, roleIds) {
  return request.axiosIns({
    url: '/apollo-ucenter/grant',
    method: 'post',
    params: {
      userIds,
      roleIds,
    },
  })
}

export function unlock(userIds) {
  return request.axiosIns({
    url: '/apollo-ucenter/unlock',
    method: 'post',
    params: {
      userIds,
    },
  })
}
// 获取租户列表
export function getTenants() {
  return request.axiosIns({
    url: '/apollo-upms/tenant/select',
    method: 'get',
  })
}
// 获取用户平台列表
export function getUserType() {
  return request.axiosIns({
    url: '/apollo-upms/dict/dictionary?code=user_type',
    method: 'get',
  })
}

// 获取角色树
export function roleTree() {
  return request.axiosIns({
    url: '/apollo-upms/role/tree',
    method: 'get',
  })
}
// 获取机构树
export function deptTree() {
  return request.axiosIns({
    url: '/apollo-upms/dept/tree',
    method: 'get',
  })
}
// 获取岗位列表
export function getPosts() {
  return request.axiosIns({
    url: '/apollo-upms/post/select',
    method: 'get',
  })
}

// 获取用户按钮权限
export function getButtons() {
  return request.axiosIns({
    url: '/apollo-upms/menu/buttons',
    method: 'get',
  })
}
