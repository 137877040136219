import Vue from 'vue'
import { getCurrentInstance } from '@vue/composition-api'
import NProgress from 'nprogress'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import website from '@/config/website'
import { Base64 } from 'js-base64'
import jwtDefaultConfig from './jwtDefaultConfig'

var errorTime = 0

const PERIOD = 2000

// NProgress 配置
NProgress.configure({
  showSpinner: false,
})
export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        NProgress.start()
        // Get token from localStorage
        const accessToken = this.getToken()
        // If token is present add it to request's Authorization Header
        if (!config.noAuth) {
          config.headers.Authorization = `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`
        }
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          // config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          config.headers[website.tokenHeader] = accessToken
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {
        if (response.data.error_code) {
          Vue.prototype.$xtoast.error(response.data.error_description)
          return Promise.reject()
        }
        NProgress.done()
        return response
      },
      error => {
        NProgress.done()
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config
        if (response && response.status !== 401) {
          if (new Date().getTime() - errorTime >= PERIOD) {
            let msg
            if (response.status === 400) {
              msg = response.data.error_description || response.data.msg || '参数错误'
            } else if (response.status === 404) {
              msg = '接口不存在'
            } if (!msg) {
              msg = response.data.msg
            }
            Vue.prototype.$xtoast.error(msg || response.data.message)
            errorTime = new Date().getTime()
          }
        }
        if (response && response.status === 401) {
          if (new Date().getTime() - errorTime >= PERIOD) {
            Vue.$toast.error({
              component: ToastificationContent,
              props: {
                title: '错误',
                icon: 'BellIcon',
                text: '登录超时，请重新登录',
                variant: 'danger',
              },
            }, {
              position: 'top-right',
            })
            errorTime = new Date().getTime()
            const instance = getCurrentInstance()
            if (instance) {
              const vm = getCurrentInstance().proxy
              if (vm) {
                vm.$store.dispatch('user/toLogout')
              }
            }
          }
        }
        if (response && response.status === 4001) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://Cloudxin.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }
}
