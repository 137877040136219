export default [
  {
    path: '/user/setup/base-info',
    name: 'system-usercenter-basesettings',
    component: () => import('@/views/system/usercenter/basesettings/index.vue'),
    meta: {
      pageTitle: '个人信息',
    },
  },
  {
    path: '/user/setup/security',
    name: 'system-usercenter-securitysettings',
    component: () => import('@/views/system/usercenter/securitysettings/index.vue'),
    meta: {
      pageTitle: '安全设置',
    },
  },
]
