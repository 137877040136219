import LogType from './log-type'
import DeviceState from './device-state'
import ProviderType from './provider-type'
import FormType from './form-type'
import RuleEngine from './rule-engine'
import StorageStrategy from './storage-strategy'
import DeviceType from './device-type'

export default {
  ...LogType,
  ...DeviceState,
  ...ProviderType,
  ...FormType,
  ...RuleEngine,
  ...StorageStrategy,
  ...DeviceType,
}
