/**
 * @param {number} time
 */
/**
 * 时间戳转为多久之前
 * @param String timestamp 时间戳
 */
export function timeAgo(timestamp) {
  if (!timestamp) {
    timestamp = Number(new Date())
  }
  timestamp = parseInt(timestamp, 10)
  // 判断用户输入的时间戳是秒还是毫秒,一般前端js获取的时间戳是毫秒(13位),后端传过来的为秒(10位)
  if (timestamp.toString().length === 10) timestamp *= 1000
  let timer = (new Date()).getTime() - timestamp
  timer = parseInt(timer / 1000, 10)
  // 如果小于5分钟,则返回"刚刚",其他以此类推
  let tips = ''
  switch (true) {
    case timer < 300:
      tips = '刚刚'
      break
    case timer >= 300 && timer < 3600:
      tips = `${parseInt(timer / 60, 10)}分钟前`
      break
    case timer >= 3600 && timer < 86400:
      tips = `${parseInt(timer / 3600, 10)}小时前`
      break
    case timer >= 86400 && timer < 2592000:
      tips = `${parseInt(timer / 86400, 10)}天前`
      break
    default:
      if (timer >= 2592000 && timer < 365 * 86400) {
        tips = `${parseInt(timer / (86400 * 30), 10)}个月前`
      } else {
        tips = `${parseInt(timer / (86400 * 365), 10)}年前`
      }
  }
  return tips
}/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' },
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
