export default [
  { path: '/', redirect: { name: 'dashboard-home' } },
  {
    path: '/dashboard',
    name: 'dashboard-home',
    component: () => import('@/views/dashboard/index.vue'),
    meta: {
      pageTitle: '仪表盘',
      resource: 'dashboard',
    },
  },
]
