const DEVICE_ALARM = 'device_alarm'

export default {
  ProviderType: {
    DEVICE_ALARM,
  },
  ProviderTypeDict: [
    { text: '设备告警', value: DEVICE_ALARM },
  ],
}
