const DEVICE = 'device'
const GATEWAY = 'gateway'
const SUB_DEVICE = 'childrenDevice'

export default {
  DeviceType: {
    DEVICE,
    GATEWAY,
    SUB_DEVICE,
    DictData: [
      { text: '直连设备', value: DEVICE },
      { text: '网关设备', value: GATEWAY },
      { text: '网关子设备', value: SUB_DEVICE },
    ],
  },
}
